import { useEffect, useState } from 'react';
import { Form, Input, Select, Skeleton } from 'antd';
import { useQuery } from '@tanstack/react-query';

import useBooking from '../hooks/use-booking';
import BookingSider from './booking-sider';
import { RUT_NOT_VALID, validateMessages } from '../constants/feedback';
import { clearRut, formatRut, validateRut } from '../utils/rut';
import { getConductor } from '../services/api/conductor';
import {
    LETTERS_SPACE_ONLY_PATTERN,
    NUMBERS_ONLY_PATTERN,
} from '../constants/regex-pattern';
import { toCamelCase } from '../utils/case-converter';
import { getLineasAereas } from '../services/api/linea-aerea';
import { getCiudades } from '../services/api/ciudad';
import { getComunas } from '../services/api/comuna';

const { TextArea } = Input;

const formItemLayout = {
    labelCol: { span: 24 },
};

export default function BookingStep3() {
    const [newRut, setNewRut] = useState('');
    const [prevRut, setPrevRut] = useState('');
    const [lineasAereas, setLineasAereas] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    const [comunas, setComunas] = useState([]);

    const { booking, updateBooking, clearStep, prevStep, nextStep } =
        useBooking();
    const [form] = Form.useForm();

    const queryConductor = useQuery({
        queryKey: ['coductor', newRut.slice(0, -1)],
        queryFn: () => getConductor(newRut.slice(0, -1)),
        staleTime: Infinity,
        enabled: !!newRut,
    });

    const queryLineasAereas = useQuery({
        queryKey: ['lineas-aereas'],
        queryFn: getLineasAereas,
        staleTime: Infinity,
    });

    const queryCiudades = async () => {
        const res = await getCiudades()
        //console.log(res.data);
        setCiudades(res.data)
    }

    const queryComunas = async (idciudad) => {
        const res = await getComunas()
        let comunasparafiltrar = res.data
        let comunafiltradas = comunasparafiltrar.filter(comuna => comuna.IdCiudad === idciudad)
        setComunas(comunafiltradas)
    }

    useEffect(() => {
        if (queryConductor.data) {
            const conductor = toCamelCase(queryConductor.data.data);

            const formattedRut = formatRut(newRut);

            let values = {
                rut: formattedRut,
                nombre: '',
                apellido: '',
                correo: '',
                celular: '',
                direccion: '',
                ciudad: '',
                comuna: ''
            };

            if (conductor.rut > 0) {                                
                values = {                    
                    rut: formattedRut,
                    nombre: `${conductor.primerNombre} ${conductor.segundoNombre} ${conductor.primerApellido} ${conductor.segundoApellido}`,
                    apellido: conductor.primerApellido,
                    telefono: conductor.fono,
                    correo: conductor.email,
                    celular: conductor.celular,
                    direccion: conductor.direccComercial,
                    ciudad: conductor.idCiudad,
                    comuna: conductor.idComuna
                };

                if (conductor.idCiudad > 0) {
                    queryComunas(conductor.idCiudad)
                }
            }

            form.setFieldsValue(values);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryConductor.data, form]);

    useEffect(() => {
        if (queryLineasAereas.data) {
            let items = toCamelCase(queryLineasAereas.data.data);
            items = items.sort((a, b) => a.nombre.localeCompare(b.nombre));

            setLineasAereas(items);
        }
    }, [queryLineasAereas.data]);

    useEffect(() => {
        queryCiudades()
        setComunas([])
    },[])

    useEffect(() => {
        const values = {
            rut: formatRut(booking.step3.rut),
            nombre: booking.step3.nombre,
            direccion: booking.step3.direccion,
            ciudad: booking.step3.idciudad,
            comuna: booking.step3.idcomuna,
            telefono: booking.step3.telefono,
            celular: booking.step3.celular,
            correo: booking.step3.correo,
            lineaAerea: booking.step3.lineaAerea,
            numVuelo: booking.step3.numVuelo,
        };

        if (booking.step3.idciudad != undefined) {
            queryComunas(booking.step3.idciudad)
        }

        form.setFieldsValue(values);
    }, [form, booking]);

    const handleRutBlur = async (e) => {
        const { value } = e.target;

        if (!value || !validateRut(value)) {
            setNewRut('');
            return;
        }

        setNewRut(value);

        if (value !== prevRut) {
            setPrevRut(value);
        } else {
            const formattedRut = formatRut(value);
            const values = {
                rut: formattedRut,
            };

            form.setFieldsValue(values);
        }
    };

    const handleRutFocus = (e) => {
        const { value } = e.target;
        form.setFieldValue('rut', clearRut(value));
    };

    const handleCiudadChange = async (e) => {
        setComunas([])
        const res = await getComunas()
        let comunasparafiltrar = res.data
        let comunafiltradas = comunasparafiltrar.filter(comuna => comuna.IdCiudad === e)
        setComunas(comunafiltradas)
    }

    const handlePrevStep = async (e) => {
        e.preventDefault();

        const values = { ...form.getFieldsValue() };
        const rut = values.rut ? clearRut(values.rut) : values.rut;

        clearStep('step2');

        updateBooking('step3', {
            rut: rut,
            nombre: values.nombre,
            apellido: values.apellido,
            correo: values.correo,
            celular: values.celular,
        });

        prevStep();
    };

    const handleFinishClick = async (values) => {
        const ciudad = values.ciudad
        const ciudadSelected = ciudades.find(c => c.IdCiudad === ciudad)
        const comuna = values.comuna
        const comunaSelected = comunas.find(c => c.IdComuna === comuna)
        console.log("ciudad", ciudadSelected)
        console.log("comuna", comunaSelected)
        console.log(values);
        updateBooking('step3', {
            rut: clearRut(values.rut),
            nombre: values.nombre,
            direccion: values.direccion,
            ciudad: ciudadSelected.Ciudad,
            idciudad: ciudadSelected.IdCiudad,
            comuna: comunaSelected.Comuna,
            idcomuna: comunaSelected.IdComuna,
            telefono: values.telefono,
            celular: values.celular,
            correo: values.correo,
            observacion: values.observacion || '',
            lineaAerea: values.lineaAerea || '',
            numVuelo: values.numVuelo || '',
        });
        nextStep();
    };

    return (
        <>
            <div id='step-3' className='main'>
                <Form
                    form={form}
                    onFinish={handleFinishClick}
                    validateMessages={validateMessages}
                    autoComplete='off'
                >
                    <div className='row'>
                        <div className='col-12 col-md-3'>
                            <BookingSider showTotalSection={true} />
                        </div>

                        <div className='col-12 col-md-9'>
                            <div className='row mb-3'>
                                <div className='col-12 form-panel'>
                                    <label className='form-panel-label'>
                                        DETALLES DEL CONDUCTOR
                                    </label>
                                    <div className='form-panel-content'>
                                        <div className='row'>
                                            <div className='col-12 col-md-6 pe-md-0'>
                                                <div className='form-field b-r b-b'>
                                                    <Form.Item
                                                        name='rut'
                                                        label='RUT'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                            },
                                                            () => ({
                                                                validator(
                                                                    _,
                                                                    value
                                                                ) {
                                                                    if (
                                                                        !value ||
                                                                        validateRut(
                                                                            value
                                                                        )
                                                                    ) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject(
                                                                        new Error(
                                                                            RUT_NOT_VALID
                                                                        )
                                                                    );
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input
                                                            maxLength={12}
                                                            allowClear
                                                            onBlur={
                                                                handleRutBlur
                                                            }
                                                            onFocus={
                                                                handleRutFocus
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-6 ps-md-0'>
                                                <div className='form-field b-b'>
                                                    <Form.Item
                                                        name='nombre'
                                                        label='Nombre y Apellido'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                            },
                                                            {
                                                                pattern:
                                                                    LETTERS_SPACE_ONLY_PATTERN,
                                                                message:
                                                                    'Nombre y Apellido',
                                                            },
                                                        ]}
                                                    >
                                                        {queryConductor.isLoading ? (
                                                            <Skeleton.Input
                                                                className='ps-3 pe-3'
                                                                active
                                                                block
                                                            />
                                                        ) : (
                                                            <Input
                                                                maxLength={50}
                                                                allowClear
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-6 pe-md-0'>
                                                <div className='form-field b-r b-b'>
                                                    <Form.Item
                                                        name='direccion'
                                                        label='Dirección de residencia'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                            },
                                                        ]}
                                                    >
                                                        {queryConductor.isLoading ? (
                                                            <Skeleton.Input
                                                                className='ps-3 pe-3'
                                                                active
                                                                block
                                                            />
                                                        ) : (
                                                            <Input
                                                                maxLength={250}
                                                                allowClear
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-6 ps-md-0'>
                                                <div className='form-field b-b'>
                                                    <Form.Item
                                                        name='ciudad'
                                                        label='Ciudad'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className='input-child location w-100'
                                                            placeholder='seleccione Ciudad'
                                                            showSearch
                                                            allowClear
                                                            optionFilterProp='label'
                                                            onChange={handleCiudadChange}
                                                            options={ciudades.map(
                                                                (
                                                                    ciudad
                                                                ) => ({
                                                                    label: ciudad.Ciudad,
                                                                    value: ciudad.IdCiudad,
                                                                })
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-6 pe-md-0'>
                                                <div className='form-field b-r b-b'>
                                                    <Form.Item
                                                        name='comuna'
                                                        label='Comuna'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className='input-child location w-100'
                                                            placeholder='seleccione Comuna'
                                                            showSearch
                                                            allowClear
                                                            optionFilterProp='label'
                                                            options={comunas.map(
                                                                (
                                                                    comuna
                                                                ) => ({
                                                                    label: comuna.Comuna,
                                                                    value: comuna.IdComuna,
                                                                })
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-6 ps-md-0'>
                                                <div className='form-field b-b'>
                                                    <Form.Item
                                                        name='telefono'
                                                        label='Teléfono'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                            },
                                                            {
                                                                pattern:
                                                                    NUMBERS_ONLY_PATTERN,
                                                                message:
                                                                    'Teléfono solo acepta números',
                                                            },
                                                        ]}
                                                    >
                                                        {queryConductor.isLoading ? (
                                                            <Skeleton.Input
                                                                className='ps-3 pe-3'
                                                                active
                                                                block
                                                            />
                                                        ) : (
                                                            <Input
                                                                maxLength={9}
                                                                allowClear
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-6 pe-md-0'>
                                                <div className='form-field b-r b-b'>
                                                    <Form.Item
                                                        name='celular'
                                                        label='Celular'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                            },
                                                            {
                                                                pattern:
                                                                    NUMBERS_ONLY_PATTERN,
                                                                message:
                                                                    'Celular solo acepta números',
                                                            },
                                                        ]}
                                                    >
                                                        {queryConductor.isLoading ? (
                                                            <Skeleton.Input
                                                                className='ps-3 pe-3'
                                                                active
                                                                block
                                                            />
                                                        ) : (
                                                            <Input
                                                                maxLength={9}
                                                                allowClear
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-6 ps-md-0'>
                                                <div className='form-field b-b'>
                                                    <Form.Item
                                                        name='correo'
                                                        label='Dirección de correo'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                            },
                                                            {
                                                                type: 'email',
                                                            },
                                                        ]}
                                                    >
                                                        {queryConductor.isLoading ? (
                                                            <Skeleton.Input
                                                                className='ps-3 pe-3'
                                                                active
                                                                block
                                                            />
                                                        ) : (
                                                            <Input
                                                                maxLength={100}
                                                                allowClear
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-12 '>
                                                <div className='form-field'>
                                                    <Form.Item
                                                        name='observacion'
                                                        label='Observaciones'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                whitespace: true,
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea rows={4} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 form-panel mt-3'>
                                    <label className='form-panel-label'>
                                        VUELO DE LLEGADA (OPCIONAL)
                                    </label>
                                    <div className='form-panel-content'>
                                        <div className='row'>
                                            <div className='col-12 col-md-12'>
                                                <div className='form-field b-b'>
                                                    <Form.Item
                                                        name='lineaAerea'
                                                        label='Aerolínea'
                                                        {...formItemLayout}
                                                    >
                                                        <Select
                                                            className='input-child location w-100'
                                                            showSearch
                                                            allowClear
                                                            optionFilterProp='label'
                                                            options={lineasAereas.map(
                                                                (
                                                                    lineaAerea
                                                                ) => ({
                                                                    label: lineaAerea.nombre,
                                                                    value: lineaAerea.codigo,
                                                                })
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-12'>
                                                <div className='form-field b-b'>
                                                    <Form.Item
                                                        name='numVuelo'
                                                        label='Número de Vuelo'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                whitespace: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            maxLength={50}
                                                            allowClear
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-6 mt-2'>
                            <button
                                className='anterior button button-prev'
                                onClick={handlePrevStep}
                            >
                                <i className='bi bi-chevron-left'></i> BUSCAR
                                AUTO
                            </button>
                        </div>

                        <div className='col-6 mt-2 text-end'>
                            <button
                                type='submit'
                                className='siguiente button button-next'
                            >
                                RESUMEN DE LA RESERVA{' '}
                                <i className='bi bi-chevron-right'></i>
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
}

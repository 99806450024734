import { useEffect, useState } from 'react';

const dayNames = [
    '',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
    'Domingo',
];

export default function MarkerModal({ sucursal, onClose, onSelect }) {
    const [scheduleList, setScheduleList] = useState([]);
    const [isPickup, setIsPickup] = useState(true);

    useEffect(() => {
        const scheduleList = generateScheduleList(sucursal.horarios, true);
        setScheduleList(scheduleList);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sucursal.horarios]);

    const generateScheduleList = (schedule, isPickup) => {
        const result = [];

        schedule.forEach((item) => {
            for (let i = item.idDiaDesde; i <= item.idDiaHasta; i++) {
                const dayName = dayNames[i];

                result.push({
                    dayName,
                    fromHour: isPickup
                        ? item.horarioEntregaDesde
                        : item.horarioDevolucionDesde,
                    toHour: isPickup
                        ? item.horarioEntregaHasta
                        : item.horarioDevolucionHasta,
                });
            }
        });

        return result;
    };

    const handleLugarRecogidaClick = (e) => {
        e.preventDefault();

        setIsPickup(true);

        const scheduleList = generateScheduleList(sucursal.horarios, true);
        setScheduleList(scheduleList);
    };

    const handleLugarDevolucionClick = (e) => {
        e.preventDefault();

        setIsPickup(false);

        const scheduleList = generateScheduleList(sucursal.horarios, false);
        setScheduleList(scheduleList);
    };

    const handleSeleccionarClick = (e, sucursal) => {
        e.preventDefault();
        onSelect(sucursal);
    };

    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    top: '2%',
                    left: '2%',
                    width: '96%',
                    height: '71%',
                    backgroundColor: 'white',
                    padding: '20px',
                    zIndex: 10,
                }}
            >
                <div className='row'>
                    <div className='col-12 col-md-12'>
                        <div id='location-info-frame' className='location'>
                            <div data-location-id={10320}>
                                <div className='location-info-frame-header'>
                                    <h4 className='header'>
                                        {sucursal.oficina}
                                    </h4>
                                    <a
                                        className='meta-icon-close'
                                        href='#'
                                        onClick={onClose}
                                        title='Cierra esta ventana'
                                    >
                                        <i className='bi bi-x-lg' />
                                    </a>
                                </div>
                                <div className='location-info-frame-meta-1 mb-3'>
                                    <div className='row'>
                                        <div className='col-12 col-md-4'>
                                            <div className='price text-center'>
                                                <span className='meta-icon-car-price'>
                                                    <i className='bi bi-car-front' />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='location-info-frame-meta-2'>
                                    <div className='row'>
                                        <div className='col-12 col-md-6'>
                                            <label>DIRECCIÓN</label>
                                            <div>
                                                {sucursal.oficina}
                                                <br />
                                                {sucursal.direccion.direccion}
                                                <br />
                                                {sucursal.direccion.region}
                                                <br />
                                                Chile
                                            </div>
                                            <label>Contacto</label>
                                            <div>
                                                Teléfono:{' '}
                                                {sucursal.direccion.fono}
                                                <br />
                                                Celular:{' '}
                                                {
                                                    sucursal.direccion
                                                        .celEmergencia
                                                }
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <label>Horas de trabajo</label>
                                            <ul>
                                                {scheduleList.map(
                                                    (schedule) => (
                                                        <li
                                                            key={
                                                                schedule.dayName
                                                            }
                                                        >
                                                            <span>
                                                                {
                                                                    schedule.dayName
                                                                }
                                                            </span>
                                                            <span>
                                                                {
                                                                    schedule.fromHour
                                                                }
                                                                -
                                                                {
                                                                    schedule.toHour
                                                                }
                                                            </span>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='location-info-frame-button'>
                                    <div className='row mt-4'>
                                        <div className='col-12 col-md-9'>
                                            <button
                                                className={`button btn-modal ${
                                                    isPickup
                                                        ? 'btn-gray'
                                                        : 'btn-white'
                                                }`}
                                                onClick={
                                                    handleLugarRecogidaClick
                                                }
                                            >
                                                Lugar de recogida
                                            </button>
                                            <button
                                                className={`button btn-modal ${
                                                    !isPickup
                                                        ? 'btn-gray'
                                                        : 'btn-white'
                                                } ms-2`}
                                                onClick={
                                                    handleLugarDevolucionClick
                                                }
                                            >
                                                Lugar de devolución
                                            </button>
                                        </div>
                                        <div className='col-12 col-md-3 text-end'>
                                            <button
                                                className='button btn-modal btn-red'
                                                onClick={(e) =>
                                                    handleSeleccionarClick(
                                                        e,
                                                        sucursal
                                                    )
                                                }
                                            >
                                                Seleccionar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export const generateTimeIntervals = (startTime, endTime) => {
    const intervals = [0, 15, 30, 45];
    const result = [];

    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);

    let currentHour = startHour;
    let currentMinute = startMinute;

    // Ajusta al siguiente intervalo válido si el minuto de inicio no se alínea con los intervalos
    currentMinute =
        intervals.find((interval) => interval >= currentMinute) || 0;

    while (
        currentHour < endHour ||
        (currentHour === endHour && currentMinute <= endMinute)
    ) {
        let timeString = `${String(currentHour).padStart(2, '0')}:${String(
            currentMinute
        ).padStart(2, '0')}`;
        result.push(timeString);

        // Avanza al siguiente intervalo
        const nextIntervalIndex =
            (intervals.indexOf(currentMinute) + 1) % intervals.length;
        currentMinute = intervals[nextIntervalIndex];

        // Incrementa la hora si vuelve a 0
        if (currentMinute === 0) {
            currentHour++;
        }

        // Romple el bucle si el siguiente intervalo excede endTime
        if (
            currentHour > endHour ||
            (currentHour === endHour && currentMinute > endMinute)
        ) {
            break;
        }
    }

    return result;
};

import api from '.';

export const getSucursales = async () => {
    // const response = await api.get('/sucursales/2');
    const response = await api.get('?action=get_sucursales');
    return response.data;
};

export const getFechasSucursal = async (idSucursal) => {
    const params = {
        idSucursal: idSucursal
    };
    const response = await api.post(`?action=valida_fecha_sucursal`, params);
    return response.data;
};

import dayjs from 'dayjs';
import { createContext, useReducer } from 'react';

import { DAYJS_DATE_DISPLAY_FORMAT } from '../constants/date-time';

const initialBookingState = {
    currentStep: 0,
    step1: {
        idSucursalSalida: process.env.REACT_APP_ID_SUCURSAL_COSTANERA_FIRST,
        idSucursalDevolucion: process.env.REACT_APP_ID_SUCURSAL_COSTANERA_FIRST,
        fechaSalida: '',
        horaSalida: undefined,
        fechaDevolucion: '',
        horaDevolucion: undefined,

        latitudSucursalSalida: 0,
        longitudSucursalSalida: 0,

        descripcionSucursalSalida: '',
        direccionSucursalSalida: '',
        regionSucursalSalida: '',
        descripcionSucursalDevolucion: '',
        direccionSucursalDevolucion: '',
        regionSucursalDevolucion: '',

        fechasSucursalSalida: [],
        fechasSucursalDevolucion: [],
        horasSalida: [],
        horasDevolucion: [],
        minFechaDevolucion: dayjs(new Date(), DAYJS_DATE_DISPLAY_FORMAT),
    },
    step2: {
        idClaseVehiculo: '',
        idTarifa: '',
        modelo: null,
        dias: 0,
        valorOpcionalesSinIva: 0,
        iva: 0,
        total: 0,
        opcionales: [],

        totalBrutoVehiculo: 0,
        totalBrutoOpcionales: 0,
        totalIva: 0,
        totalNeto: 0,
        totalNetoDia: 0,
    },
    step3: {
        rut: '',
        nombre: '',
        direccion: '',
        ciudad: '',
        idciudad: 0,
        comuna: '',
        idcomuna: 0,
        telefono: '',
        celular: '',
        correo: '',
        observacion: '',
        lineaAerea: undefined,
        numVuelo: '',
    },
};

const BookingContext = createContext({
    booking: initialBookingState,
    prevStep: () => {},
    nextStep: () => {},
    gotToStep: () => {},
    updateBooking: () => {},
    clearBooking: () => {},
    clearStep: () => {},
});

const bookingReducer = (state, action) => {
    switch (action.type) {
        case 'MOVE_PREV_STEP':
            return {
                ...state,
                currentStep: state.currentStep - 1,
            };
        case 'MOVE_NEXT_STEP':
            return {
                ...state,
                currentStep: state.currentStep + 1,
            };
        case 'MOVE_TO_STEP':
            return {
                ...state,
                currentStep: action.step,
            };
        case 'UPDATE_STEP_DATA':
            return {
                ...state,
                [action.step]: {
                    ...state[action.step],
                    ...action.data,
                },
            };
        case 'CLEAR_BOOKING':
            return {
                ...state,
                ...initialBookingState,
            };
        case 'CLEAR_STEP':
            return {
                ...state,
                [action.step]: {
                    ...state[action.step],
                    ...initialBookingState[action.step],
                },
            };
        default:
            return state;
    }
};

export const BookingProvider = ({ children }) => {
    const [bookingState, bookingDispatch] = useReducer(
        bookingReducer,
        initialBookingState
    );

    const handleNextStep = () => {
        bookingDispatch({ type: 'MOVE_NEXT_STEP' });
    };

    const handlePrevStep = () => {
        bookingDispatch({ type: 'MOVE_PREV_STEP' });
    };

    const handleGoToStep = (step) => {
        bookingDispatch({ type: 'MOVE_TO_STEP', step });
    };

    const handleUpdateBooking = (step, data) => {
        bookingDispatch({ type: 'UPDATE_STEP_DATA', step, data });
    };

    const handleClearBooking = () => {
        bookingDispatch({ type: 'CLEAR_BOOKING' });
    };

    const handleClearStep = (step) => {
        bookingDispatch({ type: 'CLEAR_STEP', step });
    };

    const contextValue = {
        booking: bookingState,
        prevStep: handlePrevStep,
        nextStep: handleNextStep,
        gotToStep: handleGoToStep,
        updateBooking: handleUpdateBooking,
        clearBooking: handleClearBooking,
        clearStep: handleClearStep,
    };

    return (
        <BookingContext.Provider value={contextValue}>
            {children}
        </BookingContext.Provider>
    );
};

export default BookingContext;

import api from '.';

export const getConductor = async (rut) => {
    const params = {
        Rut : rut
    }
    const response = await api.post(`?action=busca_conductor`, params);

    return response.data;
};

import { Result } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function PaymentFailed() {
    const navigate = useNavigate();
    const numeroReserva = localStorage.getItem('first-numero-reserva') ?? '';

    const handleClick = () => {
        localStorage.removeItem('first-numero-reserva');

        navigate('/');
    };
    return (
        <>
            <Result
                status='error'
                title='Ocurrió un error al realizar el pago de su reserva!'
                subTitle={`Número de Reserva: ${numeroReserva}`}
                extra={[
                    <div class='main'>
                        <button
                            className='button btn-red'
                            onClick={handleClick}
                        >
                            Volver al Inicio
                        </button>
                    </div>,
                ]}
            />
        </>
    );
}

import api from '.';

export const createReserva = async (
    idSucursalSalida,
    idSucursalDevolucion,
    fechaSalida,
    horaSalida,
    fechaDevolucion,
    horaDevolucion,
    idClase,
    idTarifa,
    rut,
    dv,
    nombre,
    direccion,
    correo,
    idcomuna,
    idciudad,
    telefono,
    celular,
    observacion,
    lineaAerea,
    numVuelo,
    opcionales
) => {
    const data = {
        IdReserva: '',
        FechaSalida: fechaSalida,
        HoraSalida: horaSalida,
        FechaDevolucion: fechaDevolucion,
        HoraDevolucion: horaDevolucion,
        IdSucursalSalida: idSucursalSalida,
        IdSucursalDevolucion: idSucursalDevolucion,
        IdClase: idClase,
        Idtarifa: idTarifa,
        IsReservaEmpresa: false,
        Contacto: {
            Rut: rut,
            Dv: dv,
            Nombre: nombre,
            Direccion: direccion,
            Correo: correo,
            IdComuna: String(idcomuna),
            IdCiudad: String(idciudad),
            Fono: telefono,
            Celular: celular,
            Licencia: '',
            IdNacionalidad: 0,
            Pasaporte: '',
        },
        IdPromocion: 0,
        LineaAerea: lineaAerea,
        NumVuelo: numVuelo,
        Observacion: observacion,
        RutEmpresa: null,
        RutContacto: rut,
        Opcionales: opcionales,
        CodigoCupon: null,
        RutCupon: 0,
        DVCupon: null,
        res_EntregarEn: null,
        res_DevolverEn: null,
        IdCotizacionVentasRac: null,
        IdNegocio: null,
        IdAlianza: 0,
    };

    const response = await api.post('?action=create_reserva', data);
    return response.data;
};

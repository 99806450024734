import api from '.';

export const getOpcionales = async (idClase, idSucursalSalida) => {
    const data = {
        IdClase: idClase,
        IdSucursalSalida: idSucursalSalida,
    };

    const response = await api.post('?action=create_opcionales_dispo', data);

    return response.data;
};

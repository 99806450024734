import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { Image, notification } from 'antd';

import useBooking from '../hooks/use-booking';
import { getVehiculos } from '../services/api/vehiculo';
import VehicleFeatures from './vechicle-features';
import { formatNumber } from '../utils/number';
import { toCamelCase } from '../utils/case-converter';
import { FALLBACK_VEHICLE_IMAGE } from '../constants/fallback';

const FeaturesButton = ({ onClick, vehiculo }) => {
    const [showFeatures, setShowFeatures] = useState(true);

    const handleFeaturesClick = () => {
        setShowFeatures(!showFeatures);
        onClick();
    };

    return (
        <>
            {showFeatures && vehiculo.cantVeh == 0 && (
                <>
                <span
                    className='vehicle-description-button'
                    onClick={handleFeaturesClick}
                >
                    VEHÍCULO NO DISPONIBLE                    
                </span>
                <span>
                Consulte alternativas con nuestro Call Center  (+56 2) 2225 63 28
                </span>
                </>
            )}

            {showFeatures && vehiculo.cantVeh > 0 && (
                <span
                    className='vehicle-description-button'
                    onClick={handleFeaturesClick}
                >                
                    Ver información del vehículo
                </span>
            )}

            {!showFeatures && (
                <span
                    className='vehicle-description-button'
                    onClick={handleFeaturesClick}
                >
                    Cerrar información del vehículo
                </span>
            )}
        </>
    );
};

const VehicleImage = ({ modelo }) => {
    let src = 'error';

    if (modelo.imagen) {
        src = modelo.imagen;
    }

    return <Image src={src} fallback={FALLBACK_VEHICLE_IMAGE} />;
};

export default function VehicleList({ onSelect }) {
    const [vehiculos, setVehiculos] = useState([]);
    const [clickedVehiculos, setClickedVehiculos] = useState([]);
    const [selectedVehiculo, setSelectedVehiculo] = useState(null);
    const [api, contextHolder] = notification.useNotification();

    const { booking, updateBooking } = useBooking();

    const query = useQuery({
        queryKey: [
            'vehiculos',
            booking.step1.idSucursalSalida,
            booking.step1.idSucursalDevolucion,
            booking.step1.fechaSalida,
            booking.step1.horaSalida,
            booking.step1.fechaDevolucion,
            booking.step1.horaDevolucion,
        ],
        queryFn: () =>
            getVehiculos(
                booking.step1.idSucursalSalida,
                booking.step1.idSucursalDevolucion,
                booking.step1.fechaSalida,
                booking.step1.horaSalida,
                booking.step1.fechaDevolucion,
                booking.step1.horaDevolucion
            ),

        staleTime: Infinity,
        enabled: false,
    });

    useEffect(() => {
        query.refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (query.data) {
            const mock = {
                Correo: '',
                Vehiculos: [
                    {
                        Disponible: true,
                        IdClaseVehiculo: 'DF',
                        DescClase: 'PEQUEÑO',
                        IdTarifa: 'KLF',
                        ValorDiario: 25463,
                        ValorIva: 4838,
                        ValorDiarioConIva: 30301,
                        CantVeh: 18,
                        Caracteristicas: [
                            {
                                Clave: 'Transmisión',
                                Valor: 'MEC',
                            },
                            {
                                Clave: 'Tipo Combustible',
                                Valor: 'BNC',
                            },
                            {
                                clave: 'Número de puertas',
                                valor: 5,
                            },
                        ],
                        modelos: [
                            {
                                Marca: 'Suzuki',
                                Modelo: 'Swift',
                                Imagen: 'https://first.cl/wp-content/uploads/2023/07/berlingo-patente-900x900-1-pscbrajmm8o42tffjbiw94t9p5bhqojyx1a2h6oes0.jpg',
                            },
                            {
                                Marca: 'MG',
                                Modelo: '# 1.5 MT oSimilar',
                                Imagen: 'https://first.cl/wp-content/uploads/2023/07/berlingo-patente-900x900-1-pscbrajmm8o42tffjbiw94t9p5bhqojyx1a2h6oes0.jpg',
                            },
                        ],
                    },
                ],
                Mensaje: '',
                IdTipoVehiculo: null,
                IdContactoHubspot: '',
                IdAlianza: 0,
                Dias: 2,
            };

            let data = toCamelCase(query.data.data);
            //let data = toCamelCase(mock);

            if (data.vehiculos.length == 0)
            {
                
                api.error({
                    message: 'Atención',
                    description: data.mensaje,
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
            else
            {
                // Asigna un id temporal para manejar en el front ya que la collección no viene con id
                let items = data.vehiculos.map((vehiculo) => ({
                    id: uuidv4(),
                    dias: data.dias,
                    ...vehiculo,
                }));

                setVehiculos(items);                
            }

            updateBooking('step2', {
                dias: data.dias
            });
        }
    }, [query.data]);

    const handleFeaturesClick = (vehiculo) => {
        setClickedVehiculos((prevClickedVehiculos) => {
            if (prevClickedVehiculos.find((v) => v.id === vehiculo.id)) {
                return prevClickedVehiculos.filter((v) => v.id !== vehiculo.id);
            }

            return [...prevClickedVehiculos, vehiculo];
        });
    };

    const handleSeleccionarClick = (vehiculo) => {
        setSelectedVehiculo(vehiculo);
        onSelect(vehiculo);
    };

    if (query.isFetching) {
        throw new Promise(() => {});
    }

    return (
        <ul className='list-reset'>
            {contextHolder}

            {vehiculos.map((vehiculo) => {
                const clicked = clickedVehiculos.find(
                    (v) => v.id === vehiculo.id
                );

                return (
                    <li key={vehiculo.id} className={(vehiculo.cantVeh > 0 ? '' : 'disabled-vehicle')}>
                        <div className='vehicle row' data-id=''>
                            <div className='col-12 col-md-4'>
                                <div className='vehicle-image vehicle-image-has-gallery'>
                                    {vehiculo.modelos?.map((modelo, index) => (
                                        <VehicleImage
                                            modelo={modelo}
                                            key={index}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className='col-12 col-md-5'>
                                <div className='vehicle-content'>
                                    <div className='vehicle-header'>
                                        <div>{`${vehiculo.idClaseVehiculo}-${vehiculo.descClase}`}</div>
                                    </div>
                                    <FeaturesButton
                                        onClick={() =>
                                            handleFeaturesClick(vehiculo)
                                        }
                                        vehiculo={vehiculo}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-3 order-2 order-md-1'>
                                <div className='vehicle-price'>
                                    <div>
                                        $
                                        {formatNumber(
                                            vehiculo.valorDiarioConIva
                                        )}
                                    </div>
                                    <div>
                                        ${formatNumber(vehiculo.valorDiario)}
                                        (Sin IVA) /día
                                    </div>

                                    <button
                                        className='button button-style-2'
                                        onClick={() =>
                                            handleSeleccionarClick(vehiculo)
                                        }
                                    >
                                        Seleccionar{' '}
                                        {vehiculo.id ===
                                            selectedVehiculo?.id && (
                                            <i className='background-color bi bi-check2'></i>
                                        )}
                                    </button>
                                </div>
                            </div>

                            <div className='col-12 col-md-8 offset-md-4 order-1 order-md-2'>
                                {clicked && (
                                    <VehicleFeatures vehiculo={vehiculo} />
                                )}
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}

import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DatePicker, Form, Select, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { AdvancedMarker, Map, Pin, useMap } from '@vis.gl/react-google-maps';

import { getFechasSucursal, getSucursales } from '../services/api/sucursal';
import useBooking from '../hooks/use-booking';
import { validateMessages } from '../constants/feedback';
import {
    DAYJS_DATE_DISPLAY_FORMAT,
    DAYJS_DATE_STANDARD_FORMAT,
} from '../constants/date-time';
import { toCamelCase } from '../utils/case-converter';
import { EVENTO_ID_DEVOLUCION, EVENTO_ID_SALIDA } from '../constants/evento';
import { generateTimeIntervals } from '../utils/time';
import MarkerModal from './marker-modal';

const formItemLayout = {
    labelCol: { span: 24 },
};

const INITIAL_CAMERA = {
    center: { lat: -33.4257773, lng: -70.6216206 },
    zoom: 5,
};

export default function BookingStep1() {
    const [sucursales, setSucursales] = useState([]);
    const [selectedIdSucursalSalida, setSelectedIdSucursalSalida] =
        useState('');
    const [selectedIdSucursalDevolucion, setSelectedIdSucursalDevolucion] =
        useState('');
    const [fechasSucursalSalida, setFechasSucursalSalida] = useState([]);
    const [fechasSucursalDevolucion, setFechasSucursalDevolucion] = useState(
        []
    );
    const [horasSalida, setHorasSalida] = useState([]);
    const [horasDevolucion, setHorasDevolucion] = useState([]);
    const [minFechaDevolucion, setMinFechaDevolucion] = useState(
        dayjs(new Date(), DAYJS_DATE_DISPLAY_FORMAT)
    );
    const [cameraProps, setCameraProps] = useState(INITIAL_CAMERA);
    const [markers, setMarkers] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSucursalMarker, setSelectedSucursalMarker] = useState(null);

    const [form] = Form.useForm();
    const { booking, updateBooking, nextStep } = useBooking();
    const map = useMap();

    const querySucursales = useQuery({
        queryKey: ['sucursales'],
        queryFn: getSucursales,
        staleTime: Infinity,
    });

    const queryFechasSucursalSalida = useQuery({
        queryKey: ['fechas-sucursal-salida', selectedIdSucursalSalida],
        queryFn: () => getFechasSucursal(selectedIdSucursalSalida),
        staleTime: Infinity,
        enabled: !!selectedIdSucursalSalida,
    });

    const queryFechasSucursalDevolucion = useQuery({
        queryKey: ['fechas-sucursal-devolucion', selectedIdSucursalDevolucion],
        queryFn: () => getFechasSucursal(selectedIdSucursalDevolucion),
        staleTime: Infinity,
        enabled: !!selectedIdSucursalDevolucion,
    });

    // Carga las sucursales
    useEffect(() => {
        if (querySucursales.data) {
            let items = toCamelCase(querySucursales.data.data);

            setSucursales(items);

            // Generar los marcadores de mapa
            const locations = items.map((sucursal) => ({
                key: sucursal.idSucursal,
                location: {
                    lat: +sucursal.mapa.latitud,
                    lng: +sucursal.mapa.longitud,
                },
            }));

            setMarkers(locations);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [querySucursales.data]);

    // Prellena datos del paso
    useEffect(() => {
        // Código para gatillar la carga de fechas para las sucursales por defecto
        if (booking.step1.fechasSucursalSalida.length === 0) {
            setSelectedIdSucursalSalida(booking.step1.idSucursalSalida);
            setSelectedIdSucursalDevolucion(booking.step1.idSucursalDevolucion);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFechasSucursalSalida(booking.step1.fechasSucursalSalida);
        setFechasSucursalDevolucion(booking.step1.fechasSucursalDevolucion);
        setHorasSalida(booking.step1.horasSalida);
        setHorasDevolucion(booking.step1.horasDevolucion);
        setMinFechaDevolucion(booking.step1.minFechaDevolucion);

        const values = {
            idSucursalSalida: booking.step1.idSucursalSalida,
            fechaSalida: booking.step1.fechaSalida
                ? dayjs(booking.step1.fechaSalida)
                : undefined,
            horaSalida: booking.step1.horaSalida,
            idSucursalDevolucion: booking.step1.idSucursalDevolucion,
            fechaDevolucion: booking.step1.fechaDevolucion
                ? dayjs(booking.step1.fechaDevolucion)
                : undefined,
            horaDevolucion: booking.step1.horaDevolucion,
        };
        form.setFieldsValue(values);
    }, [form, booking]);

    // Carga fechas de la sucursal
    useEffect(() => {
        if (queryFechasSucursalSalida.data) {
            const fechas = toCamelCase(queryFechasSucursalSalida.data.data);

            setFechasSucursalSalida(fechas);
        }
    }, [queryFechasSucursalSalida.data]);

    useEffect(() => {
        if (queryFechasSucursalDevolucion.data) {
            const fechas = toCamelCase(queryFechasSucursalDevolucion.data.data);

            setFechasSucursalDevolucion(fechas);
        }
    }, [queryFechasSucursalDevolucion.data]);

    const handleFinishClick = async (values) => {
        const sucursalSalida = sucursales.find(
            (sucursal) => sucursal.idSucursal === values.idSucursalSalida
        );

        const sucursalDevolucion = sucursales.find(
            (sucursal) => sucursal.idSucursal === values.idSucursalDevolucion
        );

        const fechaSalida = dayjs(values.fechaSalida).format(
            DAYJS_DATE_STANDARD_FORMAT
        );

        const fechaDevolucion = dayjs(values.fechaDevolucion).format(
            DAYJS_DATE_STANDARD_FORMAT
        );

        updateBooking('step1', {
            idSucursalSalida: values.idSucursalSalida,
            fechaSalida: fechaSalida,
            horaSalida: values.horaSalida,
            idSucursalDevolucion: values.idSucursalDevolucion,
            fechaDevolucion: fechaDevolucion,
            horaDevolucion: values.horaDevolucion,

            latitudSucursalSalida: +sucursalSalida.mapa.latitud,
            longitudSucursalSalida: +sucursalSalida.mapa.longitud,

            descripcionSucursalSalida: sucursalSalida.descripcion,
            direccionSucursalSalida: sucursalSalida.direccion.direccion,
            regionSucursalSalida: sucursalSalida.direccion.Region,
            descripcionSucursalDevolucion: sucursalDevolucion.descripcion,
            direccionSucursalDevolucion: sucursalDevolucion.direccion.direccion,
            regionSucursalDevolucion: sucursalDevolucion.direccion.region,

            fechasSucursalSalida,
            fechasSucursalDevolucion,
            horasSalida,
            horasDevolucion,
            minFechaDevolucion,
        });

        nextStep();
    };

    const handleSucursalSalidaChange = (value) => {
        setSelectedIdSucursalSalida(value);

        form.setFieldsValue({
            fechaSalida: undefined,
            horaSalida: undefined,
        });
    };

    const handleFechaSalidaChange = (date, dateString) => {
        setMinFechaDevolucion(dayjs(date, DAYJS_DATE_DISPLAY_FORMAT));

        form.setFieldsValue({
            fechaDevolucion: undefined,
            horaDevolucion: undefined,
        });

        const selectedFecha = fechasSucursalSalida.find(
            (fecha) =>
                fecha.idEvento === EVENTO_ID_SALIDA &&
                dayjs(fecha.fecha).format(DAYJS_DATE_STANDARD_FORMAT) ===
                    dayjs(date).format(DAYJS_DATE_STANDARD_FORMAT)
        );

        let hours = [];

        if (selectedFecha) {
            hours = generateTimeIntervals(
                selectedFecha.desde,
                selectedFecha.hasta
            );

            if (selectedFecha.existeRestriccion) {
                const restrictions = generateTimeIntervals(
                    selectedFecha.restriccionDesde,
                    selectedFecha.restriccionHasta
                );

                hours = hours.filter(
                    (hour) =>
                        !restrictions.some(
                            (restriction) => restriction === hour
                        )
                );
            }

            setHorasSalida(hours);
            form.setFieldsValue({ horaSalida: undefined });
        }
    };

    const handleSucursalDevolucionChange = (value) => {
        setSelectedIdSucursalDevolucion(value);

        form.setFieldsValue({
            fechaDevolucion: undefined,
            horaDevolucion: undefined,
        });
    };

    const handleFechaDevolucionChange = (date, dateString) => {
        const selectedFecha = fechasSucursalDevolucion.find(
            (fecha) =>
                fecha.idEvento === EVENTO_ID_DEVOLUCION &&
                dayjs(fecha.fecha).format(DAYJS_DATE_STANDARD_FORMAT) ===
                    dayjs(date).format(DAYJS_DATE_STANDARD_FORMAT)
        );

        let hours = [];

        if (selectedFecha) {
            hours = generateTimeIntervals(
                selectedFecha.desde,
                selectedFecha.hasta
            );

            if (selectedFecha.existeRestriccion) {
                const restrictions = generateTimeIntervals(
                    selectedFecha.restriccionDesde,
                    selectedFecha.restriccionHasta
                );

                hours = hours.filter(
                    (hour) =>
                        !restrictions.some(
                            (restriction) => restriction === hour
                        )
                );
            }

            setHorasDevolucion(hours);
            form.setFieldsValue({ horaDevolucion: undefined });
        }
    };

    const disabledFechaSalida = (current) => {
        let isFechaAvailable = false;

        // Busca fecha donde no hay restricción y si hay restricción que no sea todo el día
        const fecha = fechasSucursalSalida.find(
            (fecha) =>
                fecha.idEvento === EVENTO_ID_SALIDA &&
                dayjs(fecha.fecha).format(DAYJS_DATE_STANDARD_FORMAT) ===
                    dayjs(current).format(DAYJS_DATE_STANDARD_FORMAT) &&
                (!fecha.existeRestriccion ||
                    (fecha.existeRestriccion &&
                        (fecha.restriccionDesde !== '00:00:00' ||
                            fecha.restriccionHasta !== '23:59:59')))
        );

        if (fecha) {
            isFechaAvailable = !isFechaInRestriction(fecha);
        }

        return current && !isFechaAvailable;
    };

    const disabledFechaDevolcuion = (current) => {
        let isFechaAvailable = false;

        // Busca fecha donde no hay restricción y si hay restricción que no sea todo el
        const fecha = fechasSucursalDevolucion.find(
            (fecha) =>
                fecha.idEvento === EVENTO_ID_DEVOLUCION &&
                dayjs(fecha.fecha).format(DAYJS_DATE_STANDARD_FORMAT) ===
                    dayjs(current).format(DAYJS_DATE_STANDARD_FORMAT) &&
                (!fecha.existeRestriccion ||
                    (fecha.existeRestriccion &&
                        (fecha.restriccionDesde !== '00:00:00' ||
                            fecha.restriccionHasta !== '23:59:59')))
        );

        if (fecha) {
            isFechaAvailable = !isFechaInRestriction(fecha);
        }

        return current && !isFechaAvailable;
    };

    const isFechaInRestriction = (fecha) => {
        let isInRestriction = false;

        if (fecha) {
            // Determina si las horas no están contenidas dentro del horario de restricción
            if (fecha.existeRestriccion) {
                const fechaDesde = dayjs(`1970-01-01T${fecha.desde}`);
                const fechaRestriccionDesde = dayjs(
                    `1970-01-01T${fecha.restriccionDesde}`
                );
                const fechaHasta = dayjs(`1970-01-01T${fecha.hasta}`);
                const fechaRestriccionHasta = dayjs(
                    `1970-01-01T${fecha.restriccionHasta}`
                );

                if (
                    fechaDesde.isAfter(fechaRestriccionDesde) &&
                    fechaHasta.isBefore(fechaRestriccionHasta)
                ) {
                    isInRestriction = false;
                }
            }
        }

        return isInRestriction;
    };

    const handleZoomChanged = (newZoom) => {
        setCameraProps({ ...cameraProps, zoom: newZoom });
    };

    const handleCameraChange = (ev) => setCameraProps(ev.detail);

    const PoiMarkers = ({ pois }) => {
        return (
            <>
                {pois.map((poi) => (
                    <AdvancedMarker
                        key={poi.key}
                        position={poi.location}
                        clickable={true}
                        onClick={() => handleMarkerClick(poi)}
                    >
                        <Pin />
                    </AdvancedMarker>
                ))}
            </>
        );
    };

    const handleMarkerClick = (poi) => {
        if (!map || !poi) {
            return;
        }

        const sucursal = sucursales.find(
            (sucursal) => sucursal.idSucursal === poi.key
        );

        setSelectedSucursalMarker(sucursal);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    const handleModalSelect = (sucursal) => {
        setSelectedIdSucursalSalida(sucursal.idSucursal);

        form.setFieldsValue({
            idSucursalSalida: sucursal.idSucursal,
            fechaSalida: undefined,
            horaSalida: undefined,
        });

        setIsModalVisible(false);
    };

    return (
        <>
            <div id='step-1' className='main'>
                <Form
                    form={form}
                    onFinish={handleFinishClick}
                    validateMessages={validateMessages}
                    autoComplete='off'
                >
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <div className='row'>
                                <div className='col-12 form-panel mb-3'>
                                    <label className='form-panel-label'>
                                        Lugar de Salida
                                    </label>

                                    <div className='form-panel-content'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='form-field'>
                                                    <Form.Item
                                                        name='idSucursalSalida'
                                                        label='Lugar de Salida'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className='input-child location w-100'
                                                            optionFilterProp='label'
                                                            options={sucursales.map(
                                                                (sucursal) => ({
                                                                    label: sucursal.descripcion,
                                                                    value: sucursal.idSucursal,
                                                                })
                                                            )}
                                                            onChange={
                                                                handleSucursalSalidaChange
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-6 pe-md-0'>
                                                <div className='form-field b-t'>
                                                    <Form.Item
                                                        name='fechaSalida'
                                                        label='Fecha de Salida'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        {queryFechasSucursalSalida.isLoading && (
                                                            <Skeleton.Input
                                                                className='ps-3 pe-3'
                                                                active
                                                                block
                                                            />
                                                        )}
                                                        {!queryFechasSucursalSalida.isLoading && (
                                                            <DatePicker
                                                                format={
                                                                    DAYJS_DATE_DISPLAY_FORMAT
                                                                }
                                                                disabledDate={
                                                                    disabledFechaSalida
                                                                }
                                                                onChange={
                                                                    handleFechaSalidaChange
                                                                }
                                                                placeholder=''
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </div>
                                            </div>

                                            <div className='col-12 col-md-6 ps-md-0'>
                                                <div className='form-field b-t b-l'>
                                                    <Form.Item
                                                        name='horaSalida'
                                                        label='Hora de Salida'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className='w-100'
                                                            optionFilterProp='label'
                                                            options={horasSalida.map(
                                                                (hour) => ({
                                                                    label: hour,
                                                                    value: hour,
                                                                })
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 form-panel'>
                                    <label className='form-panel-label'>
                                        Lugar de devolución
                                    </label>
                                    <div className='form-panel-content'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='form-field'>
                                                    <Form.Item
                                                        name='idSucursalDevolucion'
                                                        label='Lugar de Devolución'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className='input-child location w-100'
                                                            optionFilterProp='label'
                                                            options={sucursales.map(
                                                                (sucursal) => ({
                                                                    label: sucursal.descripcion,
                                                                    value: sucursal.idSucursal,
                                                                })
                                                            )}
                                                            onChange={
                                                                handleSucursalDevolucionChange
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6 pe-md-0'>
                                                <div className='form-field b-t'>
                                                    <Form.Item
                                                        name='fechaDevolucion'
                                                        label='Fecha de Devolución'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        {queryFechasSucursalDevolucion.isLoading && (
                                                            <Skeleton.Input
                                                                className='ps-3 pe-3'
                                                                active
                                                                block
                                                            />
                                                        )}
                                                        {!queryFechasSucursalDevolucion.isLoading && (
                                                            <DatePicker
                                                                minDate={
                                                                    minFechaDevolucion
                                                                }
                                                                format={
                                                                    DAYJS_DATE_DISPLAY_FORMAT
                                                                }
                                                                disabledDate={
                                                                    disabledFechaDevolcuion
                                                                }
                                                                onChange={
                                                                    handleFechaDevolucionChange
                                                                }
                                                                placeholder=''
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='col-12 col-md-6 ps-md-0'>
                                                <div className='form-field b-t b-l'>
                                                    <Form.Item
                                                        name='horaDevolucion'
                                                        label='Hora de Devolución'
                                                        {...formItemLayout}
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            className='w-100'
                                                            optionFilterProp='label'
                                                            options={horasDevolucion.map(
                                                                (hour) => ({
                                                                    label: hour,
                                                                    value: hour,
                                                                })
                                                            )}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <h5>
                                        *Si eliges una hora de devolución que
                                        difiere en más de 2 horas respecto al
                                        horario de Salida, se aplicará un cargo
                                        adicional equivalente a un día completo.
                                    </h5>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-md-6'>
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                }}
                            >
                                <div
                                    className='google-map'
                                    style={{ height: 550 }}
                                >
                                    <Map
                                        mapId='GOOGLE_MAP_ID'
                                        {...cameraProps}
                                        gestureHandling={'greedy'}
                                        onZoomChanged={() =>
                                            handleZoomChanged(map.getZoom())
                                        }
                                        onCameraChanged={handleCameraChange}
                                    >
                                        <PoiMarkers pois={markers} />
                                    </Map>
                                </div>

                                {isModalVisible && (
                                    <MarkerModal
                                        sucursal={selectedSucursalMarker}
                                        onClose={handleModalClose}
                                        onSelect={handleModalSelect}
                                    />
                                )}
                            </div>
                        </div>

                        <div className='col-12 mt-2 text-end'>
                            <button className='siguiente button button-next'>
                                BUSCAR AUTO{' '}
                                <i className='bi bi-chevron-right'></i>
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
}

import { Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { GetByOcAsync, RegistrartransBank } from '../services/api/transbank';
import { useEffect } from 'react';

export default function PaymentCompleted() {
    const navigate = useNavigate();
    const numeroReserva = localStorage.getItem('first-numero-reserva') ?? '';

    const ConsultarOC = async () => {
        if (numeroReserva !== null || numeroReserva !== "") {
            //llamada al GET OC Nro de prueba:10589407
            let numeroInt = parseInt(numeroReserva)
            const res = await GetByOcAsync(numeroInt)

            if(res.status === "Ok") {
                if (res.data[0].status === 'AUTHORIZED') {
                    //Registrar Transbank con el OC
                    const registrar = await RegistrartransBank(
                        res.data[0].TransactionDate,
                        res.data[0].AuthorizationCode,
                        res.data[0].PaymentType,
                        res.data[0].SharesNumber,
                        numeroInt,
                        1,
                        String(res.data[0].Amount),
                        1
                    )
                    //console.log(registrar)
                    if (registrar.status === 200) {
                        localStorage.removeItem('first-numero-reserva');
                    }
                }
            }
        }
    }

    const handleClick = async () => {
        localStorage.removeItem('first-numero-reserva');

        navigate('/');
    };

    useEffect(()=> {
        ConsultarOC()
    },[])

    return (
        <>
            <Result
                status='success'
                title='¡Su reserva ha sido pagada con éxito!'
                subTitle={`Número de Reserva: ${numeroReserva}`}
                extra={[
                    <div className='main'>
                        <button
                            className='button btn-red'
                            onClick={handleClick}
                        >
                            Volver al Inicio
                        </button>
                    </div>,
                ]}
            />
        </>
    );
}

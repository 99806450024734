import { useState } from 'react';
import dayjs from 'dayjs';
import { Map, Marker } from '@vis.gl/react-google-maps';

import useBooking from '../hooks/use-booking';
import { DAYJS_DATE_DISPLAY_FORMAT } from '../constants/date-time';
import { formatNumber } from '../utils/number';
import PaymentModal from './payment-modal';
import { FALLBACK_VEHICLE_IMAGE } from '../constants/fallback';
import { Image } from 'antd';

const DriverDetails = () => {
    const { booking, prevStep } = useBooking();

    return (
        <div className='summary p-3'>
            <div className='summary-header'>
                <h4>Detalles del conductor</h4>{' '}
                <button id='prev-3' className='edit' onClick={prevStep}>
                    Editar
                </button>
            </div>

            <div className='summary-field'>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <div className='summary-field'>
                            <div className='summary-field-name'>
                                NOMBRE
                            </div>
                            <div className='summary-field-value'>
                                {booking.step3.nombre}
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-12 col-md-6'>
                        <div className='summary-field'>
                            <div className='summary-field-name'>APELLIDO</div>
                            <div className='summary-field-value'>
                                {booking.step3.apellido}
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className='summary-field'>
                <div className='summary-field-name'>
                    DIRECCIÓN DE RESIDENCIA
                </div>
                <div className='summary-field-value'>
                    {booking.step3.direccion}
                </div>
            </div>

            <div className='summary-field'>
                <div className='summary-field-name'>
                    CIUDAD
                </div>
                <div className='summary-field-value'>
                    {booking.step3.ciudad}
                </div>
            </div>

            <div className='summary-field'>
                <div className='summary-field-name'>
                    COMUNA
                </div>
                <div className='summary-field-value'>
                    {booking.step3.comuna}
                </div>
            </div>

            <div className='summary-field'>
                <div className='summary-field-name'>
                    DIRECCIÓN DE CORREO ELECTRÓNICO
                </div>
                <div className='summary-field-value'>
                    {booking.step3.correo}
                </div>
            </div>

            <div className='summary-field'>
                <div className='summary-field-name'>NÚMERO DE TELÉFONO</div>
                <div className='summary-field-value'>
                    {booking.step3.telefono}
                </div>
            </div>

            <div className='summary-field'>
                <div className='summary-field-name'>NÚMERO DE CELULAR</div>
                <div className='summary-field-value'>
                    {booking.step3.celular}
                </div>
            </div>
        </div>
    );
};

const BookingDetails = () => {
    const { booking } = useBooking();

    return (
        <div className='summary p-3'>
            <div className='google-map' style={{ height: 250 }}>
                {/* <iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26634.89012921619!2d-70.66185235977173!3d-33.43989322013843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c581a3493723%3A0x5fe265aa96ab0fb1!2sFirst%20Rent%20A%20Car!5e0!3m2!1ses-419!2scl!4v1711978241177!5m2!1ses-419!2scl'
                    width='100%'
                    height='250'
                    style={{ border: 0 }}
                    allowFullScreen=''
                    loading='lazy'
                    referrerpolicy='no-referrer-when-downgrade'
                    title='mapa'
                ></iframe> */}

                <Map
                    center={{
                        lat: booking.step1.latitudSucursalSalida,
                        lng: booking.step1.longitudSucursalSalida,
                    }}
                    zoom={10}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                >
                    <Marker
                        position={{
                            lat: booking.step1.latitudSucursalSalida,
                            lng: booking.step1.longitudSucursalSalida,
                        }}
                    />
                </Map>
            </div>

            <div className='summary-header'>
                <h4>Detalles de la reserva</h4>
            </div>

            <div className='summary-field'>
                <div className='summary-field-name'>Lugar de recogida</div>
                <div className='summary-field-value'>
                    {booking.step1.descripcionSucursalSalida}
                    <br />
                    {booking.step1.direccionSucursalSalida}
                    <br />
                    {booking.step1.regionSucursalSalida}
                    <br />
                    Chile
                </div>
            </div>

            <div className='summary-field'>
                <div className='summary-field-name'>
                    fecha de recogida, hora
                </div>
                <div className='summary-field-value'>
                    {dayjs(booking.step1.fechaSalida).format(
                        DAYJS_DATE_DISPLAY_FORMAT
                    )}
                    , {booking.step1.horaSalida}
                </div>
            </div>

            <div className='summary-field'>
                <div className='summary-field-name'>Lugar de devolución</div>
                <div className='summary-field-value'>
                    {booking.step1.descripcionSucursalDevolucion}
                    <br />
                    {booking.step1.direccionSucursalDevolucion}
                    <br />
                    {booking.step1.regionSucursalDevolucion}
                    <br />
                    Chile
                </div>
            </div>

            <div className='summary-field'>
                <div className='summary-field-name'>
                    Fecha de devolución, hora
                </div>
                <div className='summary-field-value'>
                    {' '}
                    {dayjs(booking.step1.fechaDevolucion).format(
                        DAYJS_DATE_DISPLAY_FORMAT
                    )}
                    , {booking.step1.horaDevolucion}
                </div>
            </div>

            <div className='summary-field'>
                <div className='summary-field-name'>Periodo de alquiler</div>
                <div className='summary-field-value'>
                    {booking.step2.dias} días
                </div>
            </div>
        </div>
    );
};

const VehicleImage = ({ modelo }) => {
    let src = 'error';

    if (modelo?.imagen) {
        src = modelo.imagen;
    }

    return <Image src={src} fallback={FALLBACK_VEHICLE_IMAGE} />;
};

const VehicleDetails = () => {
    const { booking, gotToStep } = useBooking();

    const handleEditarClick = () => {
        gotToStep(1);
    };

    return (
        <>
            <div className='summary p-3'>
                <div className='vehicle-image vehicle-image-has-gallery'>
                    {/* <img
                        src='https://first.cl/wp-content/uploads/2023/07/berlingo-patente-900x900-1-pscbrajmm8o42tffjbiw94t9p5bhqojyx1a2h6oes0.jpg'
                        alt='Haga clic para abrir la galería de vehículos.'
                    /> */}

                    <VehicleImage modelo={booking.step2.modelo} />
                </div>

                <div className='summary-header'>
                    <h4>Información del vehículo</h4>{' '}
                    <button
                        id='prev-2'
                        className='edit'
                        onClick={handleEditarClick}
                    >
                        Editar
                    </button>
                </div>

                <div className='summary-field'>
                    <div className='summary-field-name'>VEHÍCULO</div>
                    <div className='summary-field-value'>
                        Furgón Carga Mediano
                    </div>
                </div>

                <div className='summary-field'>
                    <div className='summary-field-name'>
                        CARGOS Y OPCIONES ADICIONALES
                    </div>
                    {booking.step2.opcionales.map((opcional) => (
                        <div className='summary-field-value'>
                            {opcional.cantidad} x {opcional.descripcion} - $
                            {formatNumber(opcional.valor)}
                        </div>
                    ))}
                </div>
            </div>

            <div className='summary-price-element'>
                <div>
                    <span>Vehículo seleccionado</span>
                    <span>
                        ${formatNumber(booking.step2.totalBrutoVehiculo)}
                    </span>
                </div>

                <div>
                    <span>Cargos y opciones adicionales</span>
                    <span>
                        ${formatNumber(booking.step2.totalBrutoOpcionales)}
                    </span>
                </div>

                <div>
                    <span>IVA</span>
                    <span>${formatNumber(booking.step2.totalIva)}</span>
                </div>

                <div className='summary-price-element-total'>
                    <span>Total</span>
                    <span>${formatNumber(booking.step2.totalNeto)}</span>
                </div>
            </div>
        </>
    );
};

export default function BookingStep4() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { prevStep } = useBooking();

    const handleBookClick = async () => {
        setIsModalOpen(true);
    };

    const handleCancelModalClick = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div id='step-4' className='main'>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <DriverDetails />
                    </div>

                    <div className='col-12 col-md-4'>
                        <BookingDetails />
                    </div>

                    <div className='col-12 col-md-4'>
                        <VehicleDetails />
                    </div>

                    <div className='col-6 mt-2'>
                        <button
                            className='anterior button button-prev'
                            onClick={prevStep}
                        >
                            <i className='bi bi-chevron-left'></i> DETALLES DEL
                            CONDUCTOR{' '}
                        </button>
                    </div>

                    <div className='col-6 mt-2 text-end'>
                        <button
                            className='siguiente button button-next'
                            onClick={handleBookClick}
                        >
                            PAGAR <i className='bi bi-chevron-right'></i>
                        </button>
                    </div>
                </div>
            </div>

            {isModalOpen && <PaymentModal onCancel={handleCancelModalClick} />}
        </>
    );
}

export default function VehicleFeatures({ vehiculo }) {
    let caracteristicas = [...vehiculo.caracteristicas];

    if (caracteristicas.length % 2 !== 0) {
        caracteristicas.push({ clave: '', valor: '' });
    }

    const half = Math.ceil(caracteristicas.length / 2);
    const firstHalf = caracteristicas.slice(0, half);
    const secondHalf = caracteristicas.slice(half);

    return (
        <div className='vehicle-description'>
            <div>
                <p>
                    <b>Espacio y seguridad.</b>
                    <span style={{ fontWeight: 400 }}>
                        Vehículo de carrocería cerrada con espacio para cargas,
                        idóneo para el transporte de mercancías que, por su
                        naturaleza, deben ir necesariamente protegidas.
                    </span>
                </p>
                <div className='vehicle-attribute'>
                    <ul className='list-reset'>
                        {firstHalf.map((caracteristica, index) => (
                            <li key={index}>
                                <div>{caracteristica.clave}</div>
                                <div>{caracteristica.valor}</div>
                            </li>
                        ))}
                    </ul>
                    <ul className='list-reset'>
                        {secondHalf.map((caracteristica, index) => (
                            <li key={index}>
                                <div>{caracteristica.clave}</div>
                                <div>{caracteristica.valor}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

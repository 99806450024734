export const GENERIC_ERROR_MESSAGE =
    'Ha ocurrido un error en la operación, por favor intente nuevamente';

export const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} es requerido!',
    types: {
        // eslint-disable-next-line no-template-curly-in-string
        email: '${label} no es un correo válido!',
        // eslint-disable-next-line no-template-curly-in-string
        number: '${label} no es un número válido!',
        // eslint-disable-next-line no-template-curly-in-string
        url: '${label} no es una url válida!',
    },
    number: {
        // eslint-disable-next-line no-template-curly-in-string
        range: '${label} debe estar entre ${min} y ${max}',
    },
};

export const RUT_NOT_VALID = 'RUT no es válido!';

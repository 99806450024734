import { useEffect, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import useBooking from '../hooks/use-booking';
import { getOpcionales } from '../services/api/opcional';
import { formatNumber } from '../utils/number';
import { InputNumber } from 'antd';
import { toCamelCase } from '../utils/case-converter';

export default function VehicleExtras({ vehiculo, onSelect }) {
    const { booking } = useBooking();

    const [opcionales, setOpcionales] = useState([]);
    const [selectedOpcionales, setSelectedOpcionales] = useState([]);

    const query = useSuspenseQuery({
        queryKey: [
            'opcionales',
            vehiculo.idClaseVehiculo,
            booking.idSucursalSalida,
        ],
        queryFn: () =>
            getOpcionales(
                vehiculo.idClaseVehiculo,
                booking.step1.idSucursalSalida
            ),
        staleTime: Infinity,
    });

    useEffect(() => {
        if (query.data) {
            const items = toCamelCase(query.data.data);
            setOpcionales(items);
        }
    }, [query.data]);

    useEffect(() => {
        onSelect(selectedOpcionales);
    }, [selectedOpcionales, onSelect]);

    const handleSeleccionarClick = (selectedOpcional) => {
        setSelectedOpcionales((prevSelectedOpcionales) => {
            const existingOpcional = prevSelectedOpcionales.find(
                (opcional) =>
                    opcional.idOpcionales === selectedOpcional.idOpcionales
            );

            if (existingOpcional) {
                return prevSelectedOpcionales.filter(
                    (opcional) =>
                        opcional.idOpcionales !== selectedOpcional.idOpcionales
                );
            } else {
                const updatedOpcional = opcionales.find(
                    (i) => i.idOpcionales === selectedOpcional.idOpcionales
                );
                updatedOpcional.cantidad = 1;
                return [...prevSelectedOpcionales, { ...updatedOpcional }];
            }
        });
    };

    const handleCantidadChange = (seletedOpcional, newCantidad) => {
        setOpcionales((prevOpcionales) =>
            prevOpcionales.map((opcional) =>
                opcional.idOpcionales === seletedOpcional.idOpcionales
                    ? { ...opcional, cantidadSeleccionada: Math.max(1, Math.min(seletedOpcional.cantidad, newCantidad)) }
                    : opcional
            )
        );

        setSelectedOpcionales((prevSelectedOpcionales) =>
            prevSelectedOpcionales.map((opcional) =>
                opcional.idOpcionales === seletedOpcional.idOpcionales
                    ? { ...opcional, cantidadSeleccionada: Math.max(1, Math.min(seletedOpcional.cantidad, newCantidad)) }
                    : opcional
            )
        );
    };

    const getTipoCobroOpcional = (seletedOpcional) => {
        let tipoCobro = "";
        if (seletedOpcional.base == "ARRIENDO") tipoCobro = "por arriendo";
        else if (seletedOpcional.base == "DIARIO") tipoCobro = "por día";
        return tipoCobro;
    }

    return (
        <ul className='list-reset'>
            {opcionales.map((opcional) => (
                <li data-vehicle='' key={opcional.idOpcionales}>
                    <div className='row'>
                        <div className='col-12 col-md-8 p-4'>
                            <span className='booking-form-extra-name'>
                                {opcional.descripcion}
                            </span>
                            <span className='booking-form-extra-price'>
                                <span>${formatNumber(opcional.valor)}</span> <span>{getTipoCobroOpcional(opcional)}</span>
                            </span>
                            <span className='booking-form-extra-description'>
                                {opcional.descCategoriaOpcionales}
                            </span>
                        </div>

                        <div className='col-12 col-md-2 d-flex justify-content-center align-items-center'>
                            <div className='form-field'>
                                <label>Número</label>
                                <InputNumber
                                    min={1}
                                    value={opcional.cantidadSeleccionada == undefined ? 1 : opcional.cantidadSeleccionada}
                                    onChange={(value) =>
                                        handleCantidadChange(opcional, value)
                                    }
                                />
                            </div>
                        </div>

                        <div className='col-12 col-md-2 d-flex justify-content-center align-items-center'>
                            <button
                                className='button button-style-2 state-selected state-selected-mandatory'
                                onClick={() => handleSeleccionarClick(opcional)}
                            >
                                Seleccionar{' '}
                                {selectedOpcionales.some(
                                    (el) =>
                                        el.idOpcionales ===
                                        opcional.idOpcionales
                                ) && (
                                        <i className='background-color bi bi-check2'></i>
                                    )}
                            </button>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
}

import dayjs from 'dayjs';

import useBooking from '../hooks/use-booking';
import { DAYJS_DATE_DISPLAY_FORMAT } from '../constants/date-time';
import { formatNumber } from '../utils/number';

export default function BookingSider({ showTotalSection }) {
    const { booking } = useBooking();

    return (
        <>
            <div className='summary p-3'>
                <div className='summary-header'>
                    <h4>Detalles de la reserva</h4>
                </div>

                <div className='summary-field'>
                    <div className='summary-field-name'>Lugar de recogida</div>
                    <div className='summary-field-value'>
                        {booking.step1.descripcionSucursalSalida}
                        <br />
                        {booking.step1.direccionSucursalSalida}
                        <br />
                        {/* {booking.step1.regionSucursalSalida}
                        <br /> */}
                        Chile
                    </div>
                </div>

                <div className='summary-field'>
                    <div className='summary-field-name'>
                        fecha de recogida, hora
                    </div>
                    <div className='summary-field-value'>
                        {dayjs(booking.step1.fechaSalida).format(
                            DAYJS_DATE_DISPLAY_FORMAT
                        )}
                        , {booking.step1.horaSalida}
                    </div>
                </div>

                <div className='summary-field'>
                    <div className='summary-field-name'>
                        Lugar de devolución
                    </div>
                    <div className='summary-field-value'>
                        {booking.step1.descripcionSucursalDevolucion}
                        <br />
                        {booking.step1.direccionSucursalDevolucion}
                        <br />
                        {/* {booking.step1.regionSucursalDevolucion}
                        <br /> */}
                        Chile
                    </div>
                </div>

                <div className='summary-field'>
                    <div className='summary-field-name'>
                        Fecha de devolución, hora
                    </div>
                    <div className='summary-field-value'>
                        {dayjs(booking.step1.fechaDevolucion).format(
                            DAYJS_DATE_DISPLAY_FORMAT
                        )}
                        , {booking.step1.horaDevolucion}
                    </div>
                </div>

                <div className='summary-field'>
                    <div className='summary-field-name'>
                        Periodo de alquiler
                    </div>
                    <div className='summary-field-value'>
                        {' '}
                        {booking.step2.dias} días
                    </div>
                </div>
            </div>

            {showTotalSection && (
                <div className='summary-price-element'>
                    <div>
                        <span>Vehículo seleccionado</span>
                        <span>
                            ${formatNumber(booking.step2.totalBrutoVehiculo)}
                        </span>
                    </div>

                    <div>
                        <span>Cargos y opciones adicionales</span>
                        <span>
                            ${formatNumber(booking.step2.totalBrutoOpcionales)}
                        </span>
                    </div>
                    {
                        booking.step2.recargoAeropuerto > 0 ?
                                <div>
                                    <span>Recargo aeropuerto</span>
                                    <span>
                                        ${formatNumber(booking.step2.recargoAeropuerto)}
                                    </span>
                                </div>
                            : null
                    }
                    <div>
                        <span>IVA</span>
                        <span> ${formatNumber(booking.step2.totalIva)}</span>
                    </div>

                    <div className='summary-price-element-total'>
                        <span>Total</span>
                        <span> ${formatNumber(booking.step2.totalNeto)}</span>
                    </div>
                </div>
            )}
        </>
    );
}

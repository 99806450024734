export default function BookingHeader({ items, current }) {
    return (
        <>
            <div className='navigation mb-5 mt-4'>
                {items.map((item) => {
                    const isActive = current === item.key - 1;
                    const isCompleted = current > item.key - 1;

                    return (
                        <div className='nav-item' key={item.key}>
                            <div
                                className={`circle ${isActive ? 'active' : ''}`}
                            >
                                {isCompleted ? (
                                    <i className='bi bi-check2'></i>
                                ) : (
                                    item.key
                                )}
                            </div>
                            <div className='label-nav mt-2'>{item.title}</div>
                        </div>
                    );
                })}

                <div className='line'></div>
            </div>
        </>
    );
}

import { useContext } from 'react';
import BookingContext from '../store/booking-context';

const useBooking = () => {
    const context = useContext(BookingContext);

    if (context === undefined) {
        throw new Error('useBooking must be used within a BookingContext');
    }

    return context;
};

export default useBooking;

import { useEffect } from 'react';

import BookingStep1 from './booking-step1';
import BookingStep2 from './booking-step2';
import BookingStep3 from './booking-step3';
import BookingStep4 from './booking-step4';
import BookingHeader from './booking-header';
import useBooking from '../hooks/use-booking';

export default function BookingForm() {
    const { booking } = useBooking();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [booking.currentStep]);

    const steps = [
        {
            key: 1,
            title: 'Fecha y Ubicación',
            content: <BookingStep1 />,
        },
        {
            key: 2,
            title: 'Buscar Auto',
            content: <BookingStep2 />,
        },
        {
            key: 3,
            title: 'Detalles del Conductor',
            content: <BookingStep3 />,
        },
        {
            key: 4,
            title: 'Resumen de la Reserva',
            content: <BookingStep4 />,
        },
    ];

    const items = steps.map((item) => ({
        key: item.key,
        title: item.title,
    }));

    return (
        <>
            <div className='container-fluid'>
                <BookingHeader items={items} current={booking.currentStep} />

                {steps[booking.currentStep].content}
            </div>
        </>
    );
}

import api from "."

export const GetByOcAsync = async (oc) => {
    const params = {
        OC : oc
    }

    const response = await api.post(`?action=get_by_oc`, params)
    return response.data
}

export const RegistrartransBank = async (
    Fecha,
    Codigo,
    Pago,
    Cuotas,
    Reserva,
    Dias,
    Monto,
    Tipo
) => {
    const body = {
        transaccionFecha: Fecha,
        transaccionCodigo: Codigo,
        transaccionPago: Pago,
        transaccionCuotas: Cuotas,
        transaccionReserva: Reserva,
        transaccionDias: Dias,
        transaccionMonto: Monto,
        transaccionTipo: Tipo
    }
    const response = await api.post('?action=registra_transbank',body)
    return response.data
}
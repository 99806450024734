import { BrowserRouter, Route, Routes } from 'react-router-dom';

//import './App.css';
import './App.css';
import BookingForm from './pages/booking-form';
import "@fontsource/inter"; // Defaults to weight 400
import "@fontsource/inter/400.css"; // Specify weight

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/style.css';

import PaymentCompleted from './pages/payment-completed';
import PaymentFailed from './pages/payment-failed';
import { BookingProvider } from './store/booking-context';
import { APIProvider } from '@vis.gl/react-google-maps';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path='/'
                    element={
                        <BookingProvider>
                            <APIProvider apiKey={process.env.REACT_APP_MAPS_API_KEY}>
                                <BookingForm />
                            </APIProvider>
                        </BookingProvider>
                    }
                />
                <Route path='completed' element={<PaymentCompleted />} />
                <Route path='failed' element={<PaymentFailed />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;

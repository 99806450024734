import api from '.';

export const getCotizacion = async (
    idSucursalSalida,
    idSucursalDevolucion,
    fechaSalida,
    horaSalida,
    fechaDevolucion,
    horaDevolucion,
    idClase,
    idComportamiento,
    opcionales
) => {
    const data = {
        IdClase: idClase,
        FechaSalida: fechaSalida,
        HoraSalida: horaSalida,
        FechaDevolucion: fechaDevolucion,
        HoraDevolucion: horaDevolucion,
        IdComportamiento: idComportamiento,
        IdSucursalSalida: idSucursalSalida,
        IdSucursalDevolucion: idSucursalDevolucion,
        IdPromocion: 0,
        Opcionales: opcionales,
        CodigoCupon: null,
        RutCupon: 0,
        DVCupon: null,
        Correo: null,
        IsReservaEmpresa: false,
        RutEmpresa: null,
        IdTipoVehiculo: null,
        IdNegocio: null,
        IdCotizacionVentasRac: 0,
        IdAlianza: 0,
    };

    const response = await api.post('?action=create_cotizador', data);

    return response.data;
};

import api from '.';

export const getVehiculos = async (
    idSucursalSalida,
    idSucursalDevolucion,
    fechaSalida,
    horaSalida,
    fechaDevolucion,
    horaDevolucion
) => {
    const data = {
        IdSucursalSalida: idSucursalSalida,
        IdSucursalDevolucion: idSucursalDevolucion,
        FechaSalida: fechaSalida,
        HoraSalida: horaSalida,
        FechaDevolucion: fechaDevolucion,
        HoraDevolucion: horaDevolucion,
    };

    const response = await api.post('?action=create_vehiculos_dispo', data);
    return response.data;
};

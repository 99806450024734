import { useState } from 'react';
import { Modal, Space, Spin, notification } from 'antd';

import useBooking from '../hooks/use-booking';
import { formatNumber } from '../utils/number';
import { createReserva } from '../services/api/reserva';
import { toCamelCase } from '../utils/case-converter';
import webPayImage from '../assets/img/webpay_ic.svg';

export default function PaymentModal({ onCancel }) {
    const [isFullPayment, setIsFullPayment] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    
    const { booking } = useBooking();

    const handlePagarClick = async () => {
        try {
            setIsSubmitting(true);
            // Genera reserva
            const rut = booking.step3.rut.slice(0, -1);
            const dv = booking.step3.rut.slice(-1);
            const nombre = `${booking.step3.nombre}`;

            const opcionales = booking.step2.opcionales.map((opcional) => ({
                IdOpcionales: opcional.idOpcionales,
                Cantidad: opcional.cantidadSeleccionada == undefined ? 1 : opcional.cantidadSeleccionada,
            }));

            const response = await createReserva(
                booking.step1.idSucursalSalida,
                booking.step1.idSucursalDevolucion,
                booking.step1.fechaSalida,
                booking.step1.horaSalida,
                booking.step1.fechaDevolucion,
                booking.step1.horaDevolucion,
                booking.step2.idClaseVehiculo,
                booking.step2.idTarifa,
                rut,
                dv,
                nombre,
                booking.step3.direccion,
                booking.step3.correo,
                booking.step3.idcomuna,
                booking.step3.idciudad,
                booking.step3.telefono,
                booking.step3.celular,
                booking.step3.observacion,
                booking.step3.lineaAerea,
                booking.step3.numVuelo,
                opcionales
            );

            const data = toCamelCase(response.data);

            if (data == null || data != null && data.idArriendo == 0) {
                api.error({
                    message: 'Atención',
                    description: "Error al guardar reserva. Por favor contacte a nuestro Call Center  (+56 2) 2225 63 28",
                    showProgress: true,
                    pauseOnHover: true,
                });
                setIsSubmitting(false);
            }
            else {
                localStorage.setItem('first-numero-reserva', data.idArriendo);

                // Redirecciona al hub de pagos
                const amount = isFullPayment
                    ? booking.step2.totalNeto
                    : booking.step2.totalNetoDia;

                const documents = [
                    {
                        numeroDocumento: data.idArriendo,
                        monto: amount,
                        fecha: new Date().toISOString(),
                    },
                ];

                const form = createPaymentGatewayForm(
                    data.idArriendo,
                    amount,
                    documents
                );

                document.body.appendChild(form);
                form.submit();
            }
        } catch (error) {
            console.log('🚀 ~ handlePagarClick ~ error:', error);
            setIsSubmitting(false);
        }
    };

    const createPaymentGatewayForm = (buyOrder, amount, documents) => {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = process.env.REACT_APP_PAYMENT_GATEWAY_URL;

        const buyOrderField = document.createElement('input');
        buyOrderField.type = 'hidden';
        buyOrderField.name = 'ordenCompra';
        buyOrderField.value = buyOrder;

        const amountField = document.createElement('input');
        amountField.type = 'hidden';
        amountField.name = 'monto';
        amountField.value = amount;

        const clientIdField = document.createElement('input');
        clientIdField.type = 'hidden';
        clientIdField.name = 'idCliente';
        clientIdField.value = process.env.REACT_APP_PAYMENT_GATEWAY_CLIENT_ID;

        const documentsField = document.createElement('input');
        documentsField.type = 'hidden';
        documentsField.name = 'documentos';
        documentsField.value = JSON.stringify(documents);

        form.appendChild(buyOrderField);
        form.appendChild(amountField);
        form.appendChild(clientIdField);
        form.appendChild(documentsField);

        return form;
    };

    return (
        <>
        {contextHolder}
            <Modal open={true} width={850} closable={false} footer={[]}>
                {isSubmitting && (
                    <Spin
                        size='large'
                        fullscreen
                        tip='Te estamos re-direccionando a la plataforma de pago...'
                    />
                )}

                <div class='modal-pagos'>
                    <div class='pagos'>
                        <h4 class='header'>
                            SELECCIONA TU PAGO ONLINE
                            <img src={webPayImage} alt='Webpay' />
                        </h4>
                    </div>
                    <div class='row mt-3'>
                        {
                            booking.step2.dias == 1 ?
                            <>
                                <div class='col-12 col-md-12'>
                                    <div
                                        class={`price text-center active`}
                                        role='button'
                                        onClick={() => setIsFullPayment(true)}
                                    >
                                        <label>TOTAL ARRIENDO</label>
                                        <div class='total-pago'>
                                            ${formatNumber(booking.step2.totalNeto)}
                                            <span>CLP</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                        <div class='col-12 col-md-6'>
                            <div
                                class={`price text-center ${!isFullPayment ? 'active' : ''
                                    }`}
                                role='button'
                                onClick={() => setIsFullPayment(false)}
                            >
                                <label>PREPAGAR 1 DÍA</label>
                                <div class='total-pago'>
                                    ${formatNumber(booking.step2.totalNetoDia)}
                                    <span>CLP</span>
                                </div>
                            </div>
                        </div>
                        <div class='col-12 col-md-6'>
                            <div
                                class={`price text-center ${isFullPayment ? 'active' : ''
                                    }`}
                                role='button'
                                onClick={() => setIsFullPayment(true)}
                            >
                                <label>TOTAL ARRIENDO</label>
                                <div class='total-pago'>
                                    ${formatNumber(booking.step2.totalNeto)}
                                    <span>CLP</span>
                                </div>
                            </div>
                        </div>
                            </>
                        }
                    </div>
                    <div class='terminos'>
                        <div class='row mt-3'>
                            <div class='col-12 col-md-12'>
                                <div>
                                    <h4>IMPORTANTE</h4>
                                    <ol>
                                        <li>
                                            Para garantizar un mejor servicio
                                            todas las reservas son prepagadas.
                                        </li>
                                        <li>
                                            Las cancelaciones de reservas podrán
                                            realizarse hasta 48 horas antes de
                                            la fecha y hora de recogida del
                                            vehículo y la devolución del dinero
                                            será del 100%.
                                        </li>
                                        <li>
                                            Las reservas efectuadas y/o anuladas
                                            con menos de 48 horas de
                                            anticipación no tienen opción a
                                            devolución de dinero, sólo se pueden
                                            reagendar sin fecha límite.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class='botones-pago'>
                        <div class='row mt-4'>
                            <div class='col-12 col-md-12 text-center'>
                                <Space>
                                    <button
                                        class='button btn-modal btn-gray'
                                        onClick={onCancel}
                                    >
                                        CANCELAR
                                    </button>

                                    <button
                                        class='button btn-modal btn-red'
                                        onClick={handlePagarClick}
                                    >
                                        PAGAR
                                    </button>
                                </Space>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

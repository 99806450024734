export const clearRut = (rut) => {
    return rut.replace(/\./g, '').replace('-', '');
};

export const formatRut = (rut) => {
    const format = (input) => {
        let rut = clearRut(input);

        let pos = 0; // Guarda el rut invertido con los puntos y el guión agregado.
        let sInvertido = '';
        let newRut = ''; // Guarda el resultado final del rut como debe ser

        for (let i = rut.length - 1; i >= 0; i--) {
            sInvertido += rut.charAt(i);

            if (i === rut.length - 1) sInvertido += '-';
            else if (pos === 3) {
                sInvertido += '.';
                pos = 0;
            }

            pos++;
        }

        for (let j = sInvertido.length - 1; j >= 0; j--) {
            if (sInvertido.charAt(sInvertido.length - 1) !== '.') {
                newRut += sInvertido.charAt(j);
            } else if (j !== sInvertido.length - 1) {
                newRut += sInvertido.charAt(j);
            }
        }

        let formattedRut = newRut.toUpperCase();
        let unformattedRut = clearRut(formattedRut);

        let acc = 0;

        // Factor por el cual se debe multiplicar el valor de la posición
        let factor = 2;

        // Dígito verificador
        let dv = 0;
        let realDv = 0;

        // Extraemos el último número o letra que corresponde al verificador
        // La K corresponde a 10
        if (
            unformattedRut.charAt(unformattedRut.length - 1).toUpperCase() ===
            'K'
        ) {
            realDv = 10;
        }
        //el 0 corresponde a 11
        else if (unformattedRut.charAt(unformattedRut.length - 1) === '0') {
            realDv = 11;
        } else {
            realDv = Number(unformattedRut.charAt(unformattedRut.length - 1));
        }

        for (pos = unformattedRut.length - 2; pos >= 0; pos--) {
            acc += unformattedRut.charAt(pos).valueOf() * factor;
            factor++;

            if (factor > 7) factor = 2;
        }

        dv = 11 - (acc % 11);

        if (dv === realDv) {
            return formattedRut;
        } else {
            return null;
        }
    };

    return rut ? format(rut) : null;
};

export const validateRut = (rut) => {
    return formatRut(rut) !== null;
};

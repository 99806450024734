import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            // Server responded with a status other than 200 range
            console.log(
                'Response error:',
                error.response.status,
                error.response.data
            );
        } else if (error.request) {
            // Request was made but no response received
            console.log('Request error:', error.request);
        } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error:', error.message);
        }

        // Additional handling for CORS errors
        if (
            error.message.includes('Network Error') ||
            error.message.includes('CORS')
        ) {
            console.log('It appears to be a CORS issue.');
        }

        // You can return a rejected promise to handle the error further down the promise chain
        return Promise.reject(error);
    }
);

export default api;

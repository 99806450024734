import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/style.css';
import './assets/css/custom.css';

import App from './App';

const queryClient = new QueryClient();

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <React.StrictMode>
//         <QueryClientProvider client={queryClient}>
//             <BookingProvider>
//                 <ConfigProvider locale={esES}>
//                     <App />
//                 </ConfigProvider>
//             </BookingProvider>
//         </QueryClientProvider>
//     </React.StrictMode>
// );

document.addEventListener('DOMContentLoaded', () => {
    const domNode = ReactDOM.createRoot(document.getElementById('root'));
    domNode.render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ConfigProvider locale={esES}>
                <App />
            </ConfigProvider>
        </QueryClientProvider>
      </React.StrictMode>
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

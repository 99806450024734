import { Suspense, useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Alert, notification, Result, Skeleton, Spin } from 'antd';

import VehicleList from './vehicle-list';
import BookingSider from './booking-sider';
import useBooking from '../hooks/use-booking';
import VehicleExtras from './vehicle-extras';
import { getCotizacion } from '../services/api/cotizador';
import { toCamelCase } from '../utils/case-converter';
import { Link } from 'react-router-dom';

const VehicleSkeleton = () => {
    return (
        <div className='d-flex'>
            <Skeleton.Image
                style={{
                    width: 200,
                    height: 200,
                    marginRight: 18,
                }}
                active
            />
            <Skeleton active />
        </div>
    );
};

export default function BookingStep2() {
    const [selectedVehiculo, setSelectedVehiculo] = useState(null);
    const [selectedOpcionales, setSelectedOpcionales] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [isQuoting, setIsQuoting] = useState(false);

    const { booking, updateBooking, clearStep, prevStep, nextStep, gotToStep } =
        useBooking();
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (selectedVehiculo) {
            const valorOpcionalesSinIva = selectedOpcionales.reduce(
                (acc, opcional) => acc + opcional.cantidad * opcional.valor,
                0
            );

            const subTotal =
                selectedVehiculo.valorDiario + valorOpcionalesSinIva;
            const iva = Math.round(subTotal * process.env.REACT_APP_IVA, 0);
            const total = subTotal + iva;

            updateBooking('step2', {
                idClaseVehiculo: selectedVehiculo.idClaseVehiculo,
                modelo:
                    selectedVehiculo.modelos?.length > 0
                        ? selectedVehiculo.modelos[0]
                        : null,
                idTarifa: selectedVehiculo.idTarifa,
                dias: selectedVehiculo.dias,
                valorOpcionalesSinIva,
                iva,
                total,
                opcionales: [...selectedOpcionales],
            });

            setShowAlert(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVehiculo, selectedOpcionales]);

    const handlePrevStep = () => {
        clearStep('step2');

        prevStep();
    };

    const handleVehiculoSelect = (vehiculo) => {
        setSelectedVehiculo(vehiculo);

        document
            .querySelector('#anchor-opcionales')
            .scrollIntoView({ behavior: 'smooth' });
    };

    const handleOpcionalSelect = useCallback((opcionales) => {
        setSelectedOpcionales(opcionales);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNextStepClick = async () => {
        if (!selectedVehiculo) {
            setShowAlert(true);
            window.scrollTo({ top: 0, behavior: 'smooth' });

            return;
        }

        try {
            setIsQuoting(true);

            const opcionales = selectedOpcionales.map((opcional) => ({
                IdOpcional: opcional.idOpcionales,
                Cantidad: opcional.cantidadSeleccionada == undefined ? 1 : opcional.cantidadSeleccionada,
            }));

            const response = await getCotizacion(
                booking.step1.idSucursalSalida,
                booking.step1.idSucursalDevolucion,
                booking.step1.fechaSalida,
                booking.step1.horaSalida,
                booking.step1.fechaDevolucion,
                booking.step1.horaDevolucion,
                selectedVehiculo.idClaseVehiculo,
                selectedVehiculo.idTarifa,
                opcionales
            );

            const result = toCamelCase(response.data);

            if (result.isOk) {
                updateBooking('step2', {
                    totalBrutoVehiculo: Number(
                        result.total.encabezadoNeto.replace(/\./g, '')
                    ),
                    totalBrutoOpcionales: Number(
                        result.total.opcionalesNeto.replace(/\./g, '')
                    ),
                    totalIva: Number(
                        result.total.reservaIva.replace(/\./g, '')
                    ),
                    totalNeto: Number(
                        result.total.reservaTotal.replace(/\./g, '')
                    ),
                    totalNetoDia: Number(
                        result.tarifaDia.totalAproximadoConIva.replace(
                            /\./g,
                            ''
                        )
                    ),
                });

                nextStep();
            } else {
                api.info({
                    message: 'Atención',
                    description: result.mensaje,
                    showProgress: true,
                    pauseOnHover: true,
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsQuoting(false);
        }
    };

    return (
        <>
            {contextHolder}

            <div id='step-2' className='main'>
                <div className='row'>
                    <div className='col-12 col-md-3'>
                        <BookingSider showTotalSection={false} />
                    </div>

                    <div className='col-12 col-md-9'>
                        <div className='vehicle-list'>
                            {showAlert && (
                                <Alert
                                    style={{
                                        marginBottom: 20,
                                        color: '#FFFFFF',
                                        background: '#556677',
                                    }}
                                    message='Seleccione un vehículo'
                                    type='error'
                                    banner
                                    showIcon={false}
                                />
                            )}

                            <ErrorBoundary
                                fallback={
                                    <Result
                                        status='warning'
                                        title='Ha ocurrido un error en la operación'
                                        extra={
                                            <div class='main text-center'>
                                                <Link to='/'>
                                                    <button
                                                        className='button btn-red'
                                                        onClick={() =>
                                                            gotToStep(0)
                                                        }
                                                    >
                                                        Volver al Inicio
                                                    </button>
                                                </Link>
                                            </div>
                                        }
                                    />
                                }
                            >
                                <Suspense fallback={<VehicleSkeleton />}>
                                    <VehicleList
                                        onSelect={(vehiculo) =>
                                            handleVehiculoSelect(vehiculo)
                                        }
                                    />
                                </Suspense>
                            </ErrorBoundary>
                        </div>

                        <div className='booking-extra mt-4'>
                            <h4 className='header' id='anchor-opcionales'>
                                Cargos y opciones adicionales
                            </h4>
                            <div className='booking-extra-list mt-4'>
                                <ErrorBoundary
                                    fallback={
                                        <Result
                                            status='warning'
                                            title='Ha ocurrido un error en la operación'
                                            extra={
                                                <div class='main text-center'>
                                                    <Link to='/'>
                                                        <button
                                                            className='button btn-red'
                                                            onClick={() =>
                                                                gotToStep(0)
                                                            }
                                                        >
                                                            Volver al Inicio
                                                        </button>
                                                    </Link>
                                                </div>
                                            }
                                        />
                                    }
                                >
                                    <Suspense
                                        fallback={
                                            <Skeleton
                                                active
                                                paragraph={{
                                                    rows: 4,
                                                }}
                                            />
                                        }
                                    >
                                        {selectedVehiculo && (
                                            <VehicleExtras
                                                vehiculo={selectedVehiculo}
                                                onSelect={handleOpcionalSelect}
                                            />
                                        )}
                                    </Suspense>
                                </ErrorBoundary>
                            </div>
                        </div>
                    </div>

                    <div className='col-6 mt-2'>
                        <button
                            className='anterior button button-prev'
                            onClick={handlePrevStep}
                        >
                            <i className='bi bi-chevron-left'>
                                {' '}
                                FECHA Y UBICACIÓN
                            </i>
                        </button>
                    </div>

                    <div className='col-6 mt-2 text-end'>
                        <button
                            className='siguiente button button-next'
                            onClick={handleNextStepClick}
                            disabled={isQuoting}
                        >
                            DETALLES DEL CONDUCTOR{' '}
                            <i className='bi bi-chevron-right'></i>
                            {isQuoting && <Spin className='ms-2' />}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

import { camelCase } from 'lodash';

export const toCamelCase = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((v) => toCamelCase(v));
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((result, key) => {
            const newKey = camelCase(key);
            result[newKey] = toCamelCase(obj[key]);

            return result;
        }, {});
    }

    return obj;
};
